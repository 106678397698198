
import md5 from "md5";
import { timeFix } from "@/utils/index";
import { defineComponent, reactive, ref, toRaw } from "@vue/runtime-core";
import { loginRequest } from "@/service/common";
import { notification } from "ant-design-vue";
import { useRouter } from "vue-router";
import store from "@/store";
export default defineComponent({
  setup() {
    const router = useRouter();
    const loginBtn = ref(false);
    const isLoginError = ref(false);
    const formState = reactive({
      username: "",
      password: "",
    });
    const formLogin = ref();
    const rules = {
      username: [{ required: true, message: "请输入用户名", trigger: "blur" }],
      password: [
        { required: true, message: "请输入登录密码", trigger: "blur" },
      ],
    };
    const loginSuccess = async (token: string) => {
      console.log(token);
      await store.dispatch("SetToken", token);
      router.push({ path: "/" });
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        notification.success({
          message: "欢迎",
          description: `${timeFix()}，欢迎回来`,
        });
      }, 1000);
      isLoginError.value = false;
    };

    const requestFailed = (err: any) => {
      notification["error"]({
        message: "错误",
        description: err.message || "请求出现错误，请稍后再试",
        duration: 4,
      });
    };
    const onSubmit = () => {
      console.log("submit!", toRaw(formState));
      formLogin.value
        .validate()
        .then(() => {
          loginBtn.value = true;
          loginRequest(formState)
            .then((res) => {
              if (res.code === 200) {
                loginSuccess(res.data.token);
              } else if (res.code === 201) {
                isLoginError.value = true;
              } else {
                requestFailed(res);
              }
            })
            .finally(() => (loginBtn.value = false));
        })
        .catch((error: Error) => {
          console.log("error", error);
        });
    };
    return {
      loginBtn,
      isLoginError,
      formState,
      formLogin,
      rules,
      onSubmit,
    };
  },
  mounted() {
    document.body.classList.add("userLayout");
  },
  beforeUnmount() {
    document.body.classList.remove("userLayout");
  },
});
